import {
  format,
  formatDuration,
  intervalToDuration,
  isPast,
  addDays,
  subDays,
} from "date-fns";
import formatRelative from "date-fns/formatRelative";
import formatDistanceToNowStrictRaw from "date-fns/formatDistanceToNowStrict";
import enUS from "date-fns/locale/en-US";
import { i18nTranslate } from "@/plugins/i18n";

export type FormatDistanceToNowStrictOptions = Parameters<
  typeof formatDistanceToNowStrictRaw
>[1];

type FormatOptions = Parameters<typeof format>;

/**
 * See the following for documentation:
 * - https://date-fns.org/v2.29.3/docs/format
 * - https://date-fns.org/v2.29.3/docs/Unicode-Tokens
 *
 * Example:
 * - MM/dd/yyyy HH:mm:ss a
 * - 03/20/2023, 09:40:20 PM
 */
export const dateFormats = {
  default: "MM/dd/yyyy HH:mm:ss a",
  defaultDateWithNoTime: "MM/dd/yyyy",
  dateTime: "MM/dd/yyyy, hh:mm:ss a",
  dateTimeShort: "dd.MM.yyyy, H:mm",
  time: "hh:mm:ss a",
  shortTime: "hh:mm a",
  yearFormat: "yyyy",
  dateWithNoTimeInWords: "MMMM dd, yyyy",
  dateWithNoTimeInWords_dayFirst: "dd MMMM yyyy",
  monthYearInWords: "MMMM yyyy",
};

export type DateFormat = keyof typeof dateFormats;

// using i18nTranslate causes an error on unescaped latin alphabet character
const formatRelativeLocale = {
  // When using a string, we need to wrap words with ''
  lastWeek: `'${i18nTranslate("last week")}'`,
  yesterday: `'${i18nTranslate("yesterday")}'`,
  today: `'${i18nTranslate("today")}'`,
  other: `'${i18nTranslate("on")}' LLL Y`, // LLL = Jan, Feb, etc... Y=2022 e.g. on May 2022
};

const locale = {
  ...enUS,
  formatRelative: (token) => formatRelativeLocale[token],
};

export const formatDate = (timestampStr: string, customFormat?: DateFormat) =>
  format(
    new Date(timestampStr),
    customFormat ? dateFormats[customFormat] : dateFormats.defaultDateWithNoTime
  );

export const formatTime = (timestampStr: string) =>
  format(new Date(timestampStr), dateFormats.time);

export const formatShortTime = (timestampStr: string | Date) =>
  format(new Date(timestampStr), dateFormats.shortTime);

export const formatDateTime = (timestampStr: string) =>
  format(new Date(timestampStr), dateFormats.dateTime);

export const formatDistanceToNowStrict = (
  timestampStr: string,
  options?: FormatDistanceToNowStrictOptions
) => formatDistanceToNowStrictRaw(new Date(timestampStr), options);

/**
 * Locale date-time short format
 *
 * @param date
 * @returns string locale short date-time
 */
export const formatLocaleDateTimeShort = (date: Date) => {
  /**
   * Note: this function is equivalent to (as of writing not working because of TS version)
   * date.toLocaleString("ru-RU", { dateStyle: "short", timeStyle: "short" });
   */
  return format(date, dateFormats.dateTimeShort);
};

export const dateFromNowPretty = (dateToFormat: Date | number) =>
  formatRelative(dateToFormat, new Date(), { locale });

/**
 * Thin wrapper for `date-fns` `format` function, but accepts a seconds parameter (multiplied by 1000).
 *
 * @example
 * // returns "01:30"
 * formatDateFromSeconds(90, "mm:ss")
 *
 * @example
 * // returns "10:30"
 * formatDateFromSeconds(930, "mm:ss")
 */
export const formatDateFromSeconds = (
  seconds: number,
  formatStr: FormatOptions[1],
  options?: FormatOptions[2]
) => format(seconds * 1000, formatStr, options);

export type Unit =
  | "year"
  | "month"
  | "week"
  | "day"
  | "hour"
  | "minute"
  | "second";

const SHORTEN_UNITS: Record<Unit, string> = {
  year: "yr",
  month: "mo",
  week: "wk",
  day: "day",
  hour: "hr",
  minute: "min",
  second: "sec",
};

export type FormatDurationExtraOptions = {
  shorten?: boolean;
};
export type FormatDurationOptions = Parameters<typeof formatDuration>[1] &
  FormatDurationExtraOptions;

/**
 * Formats duration from seconds to a pretty format.
 *
 * @see https://date-fns.org/docs/formatDuration#arguments for options
 *
 * @example
 * // returns "1 minute 30 seconds"
 * formatDurationFromSeconds(90);
 *
 * @example
 * // returns "1 minute, 30 seconds"
 * formatDurationFromSeconds(90, { delimiter: ", " });
 */
export const formatDurationFromSeconds = (
  seconds: number,
  formatOptions?: FormatDurationOptions
): string => {
  if (!seconds || seconds <= 0) return "";

  let durationStr = formatDuration(
    intervalToDuration({ start: 0, end: seconds * 1000 }),
    formatOptions
  );

  // Shorten results
  if (formatOptions?.shorten) {
    Object.keys(SHORTEN_UNITS).forEach((unit) => {
      durationStr = durationStr.replaceAll(unit, SHORTEN_UNITS[unit]);
    });
  }

  return durationStr;
};

/**
 * To calculate if today has passed a certain number [days]
 * of days from a certain date [timestampStr]
 *
 * @param timestampStr
 * @param days
 * @returns boolean
 */
export const hasTodayPassedNumberDaysFromDate = (
  timestampStr: string,
  days: number
) => {
  const date = new Date(timestampStr);
  const newDateSubtractedByDays = subDays(date, days + 1);
  return isPast(newDateSubtractedByDays);
};

/**
 * To calculate if a certain [timestampStr] date has passed
 * to a certain number [days] of days from that date
 *
 * @param timestampStr
 * @returns boolean
 */
export const hasDatePassedNumberDays = (timestampStr: string, days: number) => {
  const date = new Date(timestampStr);
  const newDateAddedByDays = addDays(date, days);
  return isPast(newDateAddedByDays);
};

/**
 * To calculate if a certain [timestampStr] date has passed
 *
 * @param timestampStr
 * @returns boolean
 */
export const isDatePassed = (timestampStr: string) => {
  const date = new Date(timestampStr);
  return isPast(date);
};
